<!--
 * @Author: 张博洋
 * @Date: 2021-07-15 15:32:43
 * @LastEditTime: 2021-12-07 11:10:27
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/address/edit.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="full-h over">
    <van-form class="form"
              ref='form'
              @submit="submitAction">
      <div class="p">
        <van-field v-model="params.userName"
                   name="收件人"
                   label="收件人"
                   input-align="right"
                   maxlength="20"
                   clickable
                   required
                   placeholder="请填写收件人姓名"
                   :rules="[$.rules.required]"
                   clearable>
        </van-field>
        <van-field v-model="params.userPhone"
                   type='userPhone'
                   name="手机号码"
                   input-align="right"
                   maxlength="11"
                   required
                   label="手机号码"
                   placeholder="请填写收件人手机号"
                   :rules="[$.rules.required,$.rules.oldTel(params.userPhone)]"
                   @blur="fieldBlur('userPhone')"
                   clearable />
        <city-m v-model="city"
                :required="true"
                name='地址选择'
                @change='cityChangeAction'></city-m>
        <van-field class="address"
                   name="详细地址"
                   required
                   autosize
                   label="详细地址"
                   input-align="right"
                   maxlength="100"
                   show-word-limit
                   type="textarea"
                   placeholder="街道、楼牌号等"
                   v-model="params.address"
                   :rules="[$.rules.required]"
                   @blur="fieldBlur('address')" />
      </div>

    </van-form>

    <div class="btn-group mt8">
      <van-button class="keep"
                  @click='submitAction'
                  round>保存</van-button>

    </div>
  </div>
</template>

<script>
import forms from '@/mixin/Form.js'
import button from '@/mixin/Button.js'
import cityM from '@c/cityAll'
export default {
  mixins: [forms, button],
  components: {
    cityM,
  },
  data() {
    return {
      city: '',
      params: {
        address: '',
        userName: '',
        userPhone: '',
      },
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getAddressDetail(this.$route.query.id)
    }
  },
  activated() {
    if (this.$route.query.id) {
      this.getAddressDetail(this.$route.query.id)
    }
  },
  methods: {
    getAddressDetail(id) {
      this.$axios({
        type: 'post',
        url: 'delivery/queryOne',
        data: {
          id: id,
        },
      }).then((res) => {
        let {
          address,
          userName,
          userPhone,
          provinceCode,
          provinceName,
          cityCode,
          cityName,
          countyCode,
          countyName,
        } = res.d
        this.params.address = address
        this.params.userName = userName
        this.params.userPhone = userPhone
        this.city = [
          {
            areaCode: provinceCode,
            regionName: provinceName,
          },
          {
            areaCode: cityCode,
            regionName: cityName,
          },
          {
            areaCode: countyCode,
            regionName: countyName,
          },
        ]
      })
    },
    submitAction() {
      console.log(this.params)
      this.appCheckFormAction().then(() => {
        if (this.$route.query.id) {
          this.params.id = this.$route.query.id
          this.$axios({
            type: 'post',
            url: 'delivery/update',
            data: this.params,
          }).then(() => {
            this.$toast('保存成功')
            this.$router.go(-1)
          })
        } else {
          this.$axios({
            type: 'post',
            url: 'delivery/create',
            data: this.params,
          }).then(() => {
            this.$toast('保存成功')
            this.$router.go(-1)
          })
        }
      })
    },
    // 表单失焦清空只有空格的内容
    fieldBlur(type) {
      if (this.params[type].trim().length <= 0) {
        this[type] = ''
      }
    },

    cityChangeAction() {
      // 城市选择赋值以及default
      // ['provinceCode', 'provinceName', 'cityCode', 'cityName', 'countyCode', 'countyName'].forEach((e, index) => {
      //   if()
      //   this.params[e] = this.city
      // })
      console.log(this.city)
      this.city.forEach((item, index) => {
        if (index === 0) {
          this.params.provinceCode = item.areaCode
          this.params.provinceName = item.regionName
        } else if (index === 1) {
          this.params.cityCode = item.areaCode
          this.params.cityName = item.regionName
        } else if (index === 2) {
          this.params.countyCode = item.areaCode || ''
          this.params.countyName = item.regionName || ''
        }
      })

      console.log(this.params)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
.form {
  margin: 8px 8px 0;
  .p {
    padding-top: 4px;
    padding-bottom: 8px;
    border-radius: 8px;
    background: #fff;
    overflow: hidden;
    h1 {
      padding: 12px 16px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .van-cell {
      background: none;
      font-size: 14px;
    }
    /deep/.van-field__label {
      margin-left: 16px;
      width: fit-content;
    }
    /deep/.van-cell--required::before {
      left: initial;
    }
    .default-patient {
      /deep/.van-cell__title {
        margin-left: 16px;
      }
    }
  }
}
.btn-group {
  // position: fixed;
  // bottom: 0;
  width: 100%;
  display: flex;
  padding: 10px 16px 30px 16px;
  .keep {
    width: 100%;
    flex: 1;
    background: color(primary) !important;
    color: #fff;
  }
}
</style>
